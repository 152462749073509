@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  line-height: 1.3;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Ubuntu, sans-serif;
  color: rgba(26, 26, 26, 0.9);
  -webkit-font-smoothing: antialiased;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

body:after {
  display: block;
  content: '';
  height: 0;
  width: 100%;
  background: transparent;
  transition: height 0.4s ease;
}

* {
  box-sizing: border-box;
}

.poweredby-icon {
  vertical-align: text-bottom;
  fill: rgba(26, 26, 26, 0.4);
}

.poweredby-icon.isDark {
  vertical-align: text-bottom;
  fill: hsla(0, 0%, 100%, 0.4);
}
